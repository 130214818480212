import axios from "axios";
import { handleAuthError } from "./authErrorHandler";
// Declare the base URL of the API
const url = process.env.REACT_APP_API_KEY_LOCAL;

const getAccessToken = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  return userDetails ? userDetails.token : null;
};

const createHeaders = () => {
  const accessToken = getAccessToken();

  if (accessToken) {
    return {
      "x-access-token": accessToken,
      "Content-Type": "application/json",
    };
  }
};

export const getContractList = async (data = {}) => {
  const headers = createHeaders();
  try {
    const response = await axios.post(`${url}/claim/searchClaim`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getdeleteReports = async (data) => {
  const headers = createHeaders();
  try {
    const response = await axios.post(`${url}/claim/getClaimReportings`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getEmails = async (data) => {
  const headers = createHeaders();
  try {
    const response = await axios.post(`${url}/user/getMaillogData`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getContractPrice = async (id) => {
  const headers = createHeaders();
  try {
    const response = await axios.get(`${url}/claim/getMaxClaimAmount/${id}`, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getContractValues = async (id) => {
  const headers = createHeaders();
  try {
    const response = await axios.get(`${url}/claim/getContractById/${id}`, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const checkClaimAmount = async (id) => {
  const headers = createHeaders();
  try {
    const response = await axios.get(`${url}/claim/checkClaimAmount/${id}`, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};


export const exportDataForClaim = async (data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(`${url}/claim/exportDataForClaim`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const exportDataForClaimPaid = async (data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(`${url}/claim/paidUnpaidClaimReporting`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};


export const exportDataForContract = async (data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(`${url}/contract/exportContractReporting`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const uploadClaimEvidence = async (data) => {
  const accessToken = getAccessToken(); // Assuming getAccessToken returns the access token
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  if (accessToken) {
    headers["x-access-token"] = accessToken;
  }

  try {
    const response = await axios.post(`${url}/claim/uploadReceipt`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const addClaim = async (data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(`${url}/claim/createClaim`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const checkCoverageTypeDate = async (data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(`${url}/claim/checkCoverageTypeDate`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getCustomerData = async (id) => {
  const headers = createHeaders();

  try {
    const response = await axios.get(`${url}/claim/getcustomerDetail/${id}`, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getClaimList = async (data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(`${url}/claim/getAllClaims`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getClaimListAsServicer = async (id, data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(`${url}/dealer/getDealerAsServicerClaims/${id}`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getClaimListAsServicerDealerPortal = async (data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(`${url}/dealerPortal/getDealerAsServicerClaims`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getClaimListAsServicerReseller = async (id, data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(`${url}/reseller/getResellerAsServicerClaims/${id}`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getClaimListAsServicerResellerPortal = async (data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(`${url}/resellerPortal/getResellerAsServicerClaims`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getClaimListAsContract = async (id, data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(`${url}/contract/getContractClaims/${id}`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getClaimListForDealer = async (id, data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(
      `${url}/dealer/getDealerClaims/${id}`,
      data,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getOptions = async (data, value = 1) => {
  const headers = createHeaders();
  const queryString = data
    .map((value, index) => `key[${index}]=${encodeURIComponent(value)}`)
    .join("&");

  try {
    const response = await axios.get(
      `${url}/user/getOptions/${value}?${queryString}`,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getUnpaidClaims = async (id, data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(
      `${url}/servicer/getPaidUnpaidClaims/${id}`,
      data,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const editOption = async (data) => {
  const headers = createHeaders();

  try {
    const response = await axios.put(
      `${url}/user/editOption`,
      data,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};


export const getPaidClaims = async (id, data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(
      `${url}/servicer/getPaidUnpaidClaims/${id}`,
      data,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getClaimListForReseller = async (id, data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(
      `${url}/reseller/getResellerClaims/${id}`,
      data,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getClaimListForResellerPortal = async (id, data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(
      `${url}/resellerPortal/getResellerClaims`,
      data,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getClaimListForCustomer = async (id, data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(
      `${url}/customer/customerClaims/${id}`,
      data,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getClaimListForServicer = async (id, data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(
      `${url}/servicer/getServicerClaims/${id}`,
      data,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const addClaimsRepairParts = async (id, data) => {
  const headers = createHeaders();

  try {
    const response = await axios.put(`${url}/claim/editClaim/${id}`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const editClaimStatus = async (id, data) => {
  const headers = createHeaders();

  try {
    const response = await axios.put(
      `${url}/claim/editClaimStatus/${id}`,
      data,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const editClaimServicerValue = async (id, data) => {
  const headers = createHeaders();

  try {
    const response = await axios.put(`${url}/claim/editServicer/${id}`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const DownloadReport = async (id) => {
  const headers = createHeaders();

  try {
    const response = await axios.get(`${url}/claim/getClaimReporting/${id}`, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const GenerateReport = async (id, data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(`${url}/claim/exportDataForClaim/${id}`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const deleteDownloadReport = async (id) => {
  const headers = createHeaders();

  try {
    const response = await axios.delete(`${url}/claim/deleteClaimReporting/${id}`, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const editClaimTypeValue = async (id, data) => {
  const headers = createHeaders();

  try {
    const response = await axios.put(`${url}/claim/editClaimType/${id}`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const markasPaidClaims = async (data) => {
  const headers = createHeaders();
  let claimIds = data;

  try {
    const response = await axios.post(
      `${url}/servicer/paidUnpaidClaim`,
      { claimIds },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getClaimMessages = async (id) => {
  const headers = createHeaders();

  try {
    const response = await axios.get(
      `${url}/claim/getMessages/${id}`,

      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const getClaimUnpaid = async (claimIds) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(
      `${url}/claim/getUnpaidAmount`,
      { claimIds },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const addClaimMessages = async (id, data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(`${url}/claim/sendMessages/${id}`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const addUploadCommentImage = async (data) => {
  const headers = createHeaders();

  try {
    const response = await axios.post(`${url}/claim/uploadCommentImage`, data, {
      headers: {
        ...headers,
        "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
      },
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};

export const uploadClaimInBulk = async (data) => {
  const accessToken = getAccessToken();
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  if (accessToken) {
    headers["x-access-token"] = accessToken;
  }

  try {
    const response = await axios.post(`${url}/claim/saveBulkClaim`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleAuthError(error);
    throw error;
  }
};
