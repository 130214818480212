import React, { useRef, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import Button from "../../../common/button";

import ActiveIcon from "../../../assets/images/icons/iconAction.svg";
import AddItem from "../../../assets/images/icons/addItem.svg";
import clearFilter from "../../../assets/images/icons/Clear-Filter-Icon-White.svg";
import shorting from "../../../assets/images/icons/shorting.svg";
import Search from "../../../assets/images/icons/SearchIcon.svg";
import Headbar from "../../../common/headBar";
import Grid from "../../../common/grid";
import edit from "../../../assets/images/edit-text.png";
import Input from "../../../common/input";
import DataTable from "react-data-table-component";
import { useEffect } from "react";
import {
  editCategoryList,
  getCategoryList,
} from "../../../services/priceBookService";
import Select from "../../../common/select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RotateLoader } from "react-spinners";
import Card from "../../../common/card";
import InActiveButton from "../../../common/inActiveButton";
import SingleView from "../../../common/singleView";


function Category() {
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  useEffect(() => {
    getCategoryListData();
    window.scrollTo(0, 0);
  }, []);
  const handleFilterIconClick = () => {
    formik.resetForm();
    console.log(formik.values);
    getCategoryListData();
  };

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
  };
  const getCategoryListData = async (data) => {
    try {
      setLoading(true);
      const res = await getCategoryList(data);
      setCategoryList(res.result);
    } catch (error) {
      console.error("Error fetching category list:", error);
    } finally {
      setLoading(false);
    }
  };

  const calculateDropdownPosition = (index) => {
    const isCloseToBottom = categoryList.length - index <= 10000;
    return isCloseToBottom ? "bottom-[1rem]" : "bottom-[1rem]";
  };

  const status = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];
  const formik = useFormik({
    initialValues: {
      name: "",
      status: "",
    },
    validationSchema: Yup.object({
      name: Yup.string(),
      status: Yup.string(),
    }),
    onSubmit: (values) => {
      console.log("Form submitted with values:", values);
      getCategoryListData(values);
    },
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Close the dropdown if the click is outside of it
        setSelectedAction(null);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const CustomNoDataComponent = () => (
    <Card className="text-center my-5">
      <p>No records found.</p>
    </Card>
  );
  const [currentPage, setCurrentPage] = useState(1); // Tracks current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Tracks rows per page

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };

  const columns = [
    {
      name: "Serial #",
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
      sortable: true,
      minWidth: "auto",
      maxWidth: "120px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      // cell: (row) => <span title={row.name}>{truncateText(row.name, 20)}</span>,
      minWidth: "200px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      minWidth: "350px",
      // cell: (row) => (
      //   <span title={row.description}>{truncateText(row.description, 30)}</span>
      // ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div className="relative">
          <div
            className={` ${row.status === true ? "bg-[#6BD133]" : "bg-[#FF4747]"
              } absolute h-3 w-3 rounded-full top-[33%] ml-[8px]`}
          ></div>
          <select
            value={row.status === true ? "active" : "inactive"}
            onChange={(e) => handleStatusChange(row, e.target.value)}
            className="text-[12px] border border-gray-300 text-[#727378] pl-[20px] py-2 pr-1 font-semibold rounded-xl"
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      ),
    },
    {
      name: "Action",
      minWidth: "auto",
      maxWidth: "90px",
      cell: (row, index) => {
        // console.log("===>>", index);
        return (
          <div className="relative">
            <div onClick={() => setSelectedAction(selectedAction === row.unique_key ? null : row.unique_key)}>
              <img
                src={ActiveIcon}
                className="cursor-pointer	w-[35px]"
                alt="Active Icon"
              />
            </div>
            {selectedAction === row.unique_key && (
              <SingleView ref={dropdownRef}
                className={`absolute z-[2] w-[80px] drop-shadow-5xl -right-3 mt-2 py-1 border rounded-lg shadow-md ${calculateDropdownPosition(
                  index
                )}`}
              >
                <div
                  onClick={() => navigate(`/editCategory/${row._id}`)}
                  className="text-left cursor-pointer flex py-1 px-2"
                >
                  <div
                    style={{
                      maskImage: `url(${edit})`,
                      WebkitMaskImage: `url(${edit})`,
                      maskRepeat: "no-repeat",
                      WebkitMaskRepeat: "no-repeat",
                      maskPosition: "center",
                      WebkitMaskPosition: "center",
                      maskSize: "contain",
                      WebkitMaskSize: "contain",
                    }}
                    className="self-center singleViews mr-2 h-4 w-4 "
                  />
                  {/* <img src={edit} className="w-4 h-4 mr-2" /> */}
                  Edit
                </div>
              </SingleView>
            )}
          </div>
        );
      },
    },
  ];

  const handleStatusChange = async (row, newStatus) => {
    try {
      const updatedCategoryList = categoryList.map((category) => {
        if (category._id === row._id) {
          return { ...category, status: newStatus === "active" ? true : false };
        }
        return category;
      });

      setCategoryList(updatedCategoryList);

      const result = await editCategoryList(row._id, {
        name: row.name,
        description: row.description,
        status: newStatus === "active" ? true : false,
      });

      console.log(result);

      if (result.code === 200) {
        console.log("Status updated successfully");
        // getCategoryListData();
      } else {
        // getCategoryListData();
      }
    } catch (error) {
      console.error("Error updating category status:", error);
      // getCategoryListData();
    }
  };

  return (
    <>
      <div className="mb-8 ml-3">
        <Headbar />
        <div className="flex mt-2">
          <div className="pl-3">
            <p className="font-bold text-[36px] leading-9	mb-[3px]">Category</p>
            <ul className="flex self-center">
              <li className="text-sm font-Regular">
                <Link to={"/"}>Home </Link> /{" "}
              </li>
              <li className="text-sm font-semibold ml-1 pt-[1px]">
                {" "}
                Category{" "}
              </li>
            </ul>
          </div>
        </div>
        <InActiveButton className=" flex self-center mb-3 rounded-xl ml-auto border-[1px] border-Light-Grey">
          <Link
            to={"/addCategory"}
            className="flex"
          >
            <div
              style={{
                maskImage: `url(${AddItem})`,
                WebkitMaskImage: `url(${AddItem})`,
                maskRepeat: "no-repeat",
                WebkitMaskRepeat: "no-repeat",
                maskPosition: "center",
                WebkitMaskPosition: "center",
                maskSize: "contain",
                WebkitMaskSize: "contain",
              }}
              className="self-center pr-1 py-1 h-4 w-4"
            />
            {/* <img src={AddItem} className="self-center" alt="AddItem" />{" "} */}
            <span className=" ml-2 text-[14px] font-Regular ">
              Add Category{" "}
            </span>{" "}
          </Link>
        </InActiveButton>

        <Card className="bg-white  border-[1px] border-Light-Grey rounded-xl ">
          <Grid className="!px-[26px] !pt-[14px] !pb-0">
            <div className="col-span-5 self-center">
              <p className="text-xl font-semibold">Categories List</p>
            </div>
            <div className="col-span-7">
              <div className="bg-grayf9 rounded-[30px] p-3 border-[1px] border-Light-Grey">
                <form onSubmit={formik.handleSubmit}>
                  <Grid className="">
                    <div className="col-span-5 self-center">
                      <Input
                        name="name"
                        type="text"
                        placeholder="Category Name"
                        className="!text-[14px] !bg-White-Smoke"
                        className1="!text-[13px] !pt-1 placeholder-opacity-50 !pb-1 placeholder-Black-Russian !bg-[white]"
                        label=""
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className="col-span-5 self-center">
                      <Select
                        label=""
                        name="status"
                        OptionName="Status"
                        options={status}
                        color="text-Black-Russian opacity-50"
                        className1="!pt-1 !pb-1 !text-[13px] !bg-[white]"
                        className="!text-[14px] !bg-White-Smoke "
                        value={formik.values.status}
                        onChange={formik.setFieldValue}
                      />
                    </div>
                    <div className="col-span-2 self-center flex ">
                      <Button type="submit" className="!p-2">
                        <img
                          src={Search}
                          className="cursor-pointer	mx-auto "
                          alt="Search"
                        />
                      </Button>
                      <InActiveButton
                        type="submit"
                        onClick={() => {
                          handleFilterIconClick();
                        }}
                      >
                        <div
                          style={{
                            maskImage: `url(${clearFilter})`,
                            WebkitMaskImage: `url(${clearFilter})`,
                            maskRepeat: "no-repeat",
                            WebkitMaskRepeat: "no-repeat",
                            maskPosition: "center",
                            WebkitMaskPosition: "center",
                            maskSize: "contain",
                            WebkitMaskSize: "contain",
                          }}
                          className="self-center pr-1 py-1 h-4 w-4 cursor-pointer mx-auto"
                        />
                      </InActiveButton>
                    </div>
                  </Grid>
                </form>
              </div>
            </div>
          </Grid>
          <div className="mb-5 relative">
            {loading ? (
              <div className=" h-[400px] w-full flex py-5">
                <div className="self-center mx-auto">
                  <RotateLoader color="#333" />
                </div>
              </div>
            ) : (
              <DataTable draggableColumns={false} columns={columns}
                data={categoryList}
                highlightOnHover
                sortIcon={
                  <>
                    {" "}
                    <div
                      style={{
                        maskImage: `url(${shorting})`,
                        WebkitMaskImage: `url(${shorting})`,
                        maskRepeat: "no-repeat",
                        WebkitMaskRepeat: "no-repeat",
                        maskPosition: "center",
                        WebkitMaskPosition: "center",
                        maskSize: "contain",
                        WebkitMaskSize: "contain",
                      }}
                      className="ml-2 tabless"
                    />
                    {/* <img src={shorting} className="ml-2" alt="shorting" /> */}
                  </>
                }
                noDataComponent={<CustomNoDataComponent />}
                pagination
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                paginationPerPage={10}
                paginationComponentOptions={paginationOptions}
                paginationRowsPerPageOptions={[10, 20, 50, 100]}
              />
            )}
          </div>
        </Card>
      </div>
    </>
  );
}
export default Category;
