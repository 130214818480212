import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import ActiveIcon from "../../../assets/images/icons/iconAction.svg";
import Headbar from "../../../common/headBar";
import shorting from "../../../assets/images/icons/shorting.svg";
import Grid from "../../../common/grid";
import DataTable from "react-data-table-component";
import remove from "../../../assets/images/delete.png";
import view from "../../../assets/images/eye.png";
import unassign from "../../../assets/images/Unassign.png";
import download from "../../../assets/images/download.png";
import Primary from "../../../assets/images/SetPrimary.png";
import { RotateLoader } from "react-spinners";
import Card from "../../../common/card";
import SingleView from "../../../common/singleView";
import { deleteDownloadReport, DownloadReport, getdeleteReports, getEmails } from "../../../services/claimServices";
import Modal from "../../../common/model";
import InActiveButton from "../../../common/inActiveButton";
import Button from "../../../common/button";
import { downloadFile, dowreportingTimeUpdate } from "../../../services/userServices";
import { saveAs } from "file-saver";
import Select from "../../../common/select";
import Cross from "../../../assets/images/Cross.png";
import Input from "../../../common/input";
const url = process.env.REACT_APP_API_KEY_LOCAL;

function MailLogs() {
    const [selectedAction, setSelectedAction] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleteReport, setDeleteReport] = useState();
    const [timer, setTimer] = useState(3);
    const [reportId, setReportId] = useState();
    const [report, setReport] = useState();
    const [emailTeplate, setEmailTeplate] = useState([]);
    const [values, setValues] = useState([]);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [primaryMessage, setPrimaryMessage] = useState("");
    const [secondaryMessage, setSecondaryMessage] = useState("");
    const [isArchiveOpen, setIsArchiveOpen] = useState(false);
    const [markLoader, setMarkLoader] = useState(false);
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setSelectedAction(null);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const CustomNoDataComponent = () => (
        <Card className="text-center my-5">
            <p>No records found.</p>
        </Card>
    );

    const paginationOptions = {
        rowsPerPageText: "Rows per page:",
        rangeSeparatorText: "of",
    };

    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Handle rows per page change
    const handleRowsPerPageChange = (newPerPage, page) => {
        setRowsPerPage(newPerPage);
        setCurrentPage(page);
    };

    const [currentPage, setCurrentPage] = useState(1); // Tracks current page
    const [rowsPerPage, setRowsPerPage] = useState(10); // Tracks rows per page

    const columns = [
        {
            name: "User Role",
            selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
            sortable: true,
            minWidth: "auto",
            maxWidth: "120px",
        },
        {
            name: "Account Name",
            selector: (row) => row.category,
            sortable: true,
            cell: (row) => (
                <p className="self-center"> {row.category} </p>
            ),
        },
        {
            name: (
                <div>
                    Email
                </div>
            ),
            selector: (row) => row.email,
            sortable: true,
            // minWidth: "100px",

            style: {
                whiteSpace: "pre-wrap",
                textAlign: "center",
            },
        },
        {
            name: "Subject",
            selector: (row) => row.keyValues.subject,
            sortable: true,
            cell: (row) => (
                <p className="self-center"> {row?.keyValues?.subject} </p>
            ),
        },
        {
            name: (
                <div>
                    Sent On
                </div>
            ),
            selector: (row) => {
                const date = new Date(row.sentOn);
                const formattedDate = date.toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                });
                const formattedTime = date.toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                });
                return `${formattedDate}    ${formattedTime}`;
            },
            sortable: true,
            // minWidth: "130px",
        },

        {
            name: "Status",
            selector: (row) => row.event,
            sortable: true,
        },
        {
            name: "Action",
            minWidth: "auto",
            maxWidth: "120px",
            cell: (row, index) => {
                return (
                    <div className="relative">

                        <div
                            onClick={() =>
                                setSelectedAction(selectedAction === index ? null : index)
                            }
                        >
                            <img
                                src={ActiveIcon}
                                className="cursor-pointer w-[35px]"
                                alt="Active Icon"
                            />
                        </div>
                        {selectedAction === index && (
                            <>
                                <SingleView
                                    ref={dropdownRef}
                                    className={`absolute z-[2] w-[80px] drop-shadow-5xl bottom-1 -right-3 mt-2 py-1 border rounded-lg shadow-md`}
                                >

                                    <div
                                        className="text-left py-1 px-2 flex cursor-pointer"
                                        onClick={() => openArchive(row)}
                                    >
                                        <div
                                            style={{
                                                maskImage: `url(${view})`,
                                                WebkitMaskImage: `url(${view})`,
                                                maskRepeat: "no-repeat",
                                                WebkitMaskRepeat: "no-repeat",
                                                maskPosition: "center",
                                                WebkitMaskPosition: "center",
                                                maskSize: "contain",
                                                WebkitMaskSize: "contain",
                                            }}
                                            className="self-center singleViews mr-2 h-4 w-4 "
                                        /> View
                                    </div>
                                </SingleView>
                            </>
                        )}
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        getReportListData();
        window.scrollTo(0, 0);
    }, []);

    const getReportListData = async (data) => {
        try {
            setLoading(true);
            const res = await getEmails(data);
            setDeleteReport(res.result);
        } catch (error) {
            console.error("Error fetching category list:", error);
        } finally {
            setLoading(false);
        }
    };




    useEffect(() => {
        let intervalId;
        if (isModalOpen1 && timer > 0) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }
        if (timer === 0) {
            closeArchive();
            getReportListData();
            closeModal1();
        }
        return () => clearInterval(intervalId);
    }, [isModalOpen1, timer]);

    const closeModal1 = () => {
        setIsModalOpen1(false);
    };

    const openArchive = (data) => {
        setEmailTeplate(data.content)
        setValues(data?.keyValues)
        setIsArchiveOpen(true);
    };
    const closeArchive = () => {
        setIsArchiveOpen(false);
    };

    const filterBy = [
        {
            value: "All",
            label: "All",
        },
        {
            value: "claim",
            label: "Claims",
        },
        {
            value: "contract",
            label: "Contracts",
        },
    ];

    const createMarkup = (htmlString) => {
        return { __html: htmlString };
    };
    function populateTemplate(template, data) {
        if (typeof template !== "string") {
            console.error("Template must be a string. Received:", typeof template, template);
            return "";
        }

        return template.replace(/{{\s*([\w.]+)\s*}}/g, (match, key) => {
            const keys = key.split('.');
            let value = data;
            for (const k of keys) {
                value = value[k];
                if (value === undefined) return match; // Leave placeholder if value is missing
            }
            return value;
        });
    }

    // Populate the template with dynamic data
    const populatedHTML = populateTemplate(emailTeplate, values);

    return (
        <>
            <div className="mb-8 ml-3">
                <Headbar />
                <div className="flex mt-2">
                    <div className="pl-3">
                        <p className="font-bold text-[36px] leading-9	mb-[3px]">
                            Mail Logs
                        </p>
                        <ul className="flex self-center">
                            <li className="text-sm font-Regular">
                                <Link to={"/"}>Home </Link> /{" "}
                            </li>
                            <li className="text-sm font-semibold ml-1 pt-[1px]">
                                {" "}
                                Mails List{" "}
                            </li>
                        </ul>
                    </div>
                </div>

                <Card className="mt-5  border-[1px] border-Light-Grey rounded-xl">
                    <Grid className="!px-[26px] !pb-0">
                        <div className="col-span-3 self-center">
                            <p className="text-xl font-semibold py-4">Mail Logs</p>
                        </div>
                        <div className="col-span-9">
                            {/* <form onSubmit={formik.handleSubmit}>
                                <div className="bg-grayf9 rounded-[30px] p-3 border-[1px] border-Light-Grey">
                                    <Grid className={`!grid-cols-9`}>
                                        <div className="col-span-2 self-center">
                                            <Input
                                                type="text"
                                                className="!text-[14px] !bg-White-Smoke"
                                                className1="!text-[13px] !pt-1 placeholder-opacity-50 !pb-1 placeholder-Black-Russian !bg-[white]"
                                                label=""
                                                placeholder="Contract ID"
                                                name="contractId"
                                                {...formik.getFieldProps("contractId")}
                                            />
                                        </div>
                                        {props.orderId == null ? (
                                            <>
                                                <div className="col-span-2 self-center">
                                                    <Input
                                                        name="orderId"
                                                        type="text"
                                                        className="!text-[14px] !bg-White-Smoke"
                                                        className1="!text-[13px] !pt-1 placeholder-opacity-50 !pb-1 placeholder-Black-Russian !bg-[white]"
                                                        label=""
                                                        placeholder=" Order ID"
                                                        {...formik.getFieldProps("orderId")}
                                                    />
                                                </div>
                                                <div className="col-span-2 self-center">
                                                    <Input
                                                        name="venderOrder"
                                                        type="text"
                                                        className="!text-[14px] !bg-White-Smoke"
                                                        className1="!text-[13px] !pt-1 placeholder-opacity-50 !pb-1 placeholder-Black-Russian !bg-[white]"
                                                        label=""
                                                        placeholder="Dealer P.O. #"
                                                        {...formik.getFieldProps("venderOrder")}
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="col-span-2 self-center">
                                                    <Input
                                                        type="text"
                                                        name="serial"
                                                        className="!text-[14px] !bg-White-Smoke"
                                                        className1="!text-[13px] !pt-1 placeholder-opacity-50 !pb-1 placeholder-Black-Russian !bg-[white]"
                                                        label=""
                                                        placeholder="Serial # / Device ID"
                                                        {...formik.getFieldProps("serial")}
                                                    />
                                                </div>
                                                <div className="col-span-2 self-center">
                                                    <Select
                                                        label=""
                                                        options={Eligible}
                                                        color="text-Black-Russian opacity-50"
                                                        value={value}
                                                        OptionName="Eligible"
                                                        className1="!pt-1 !pb-1 !text-[13px] !bg-[white]"
                                                        className="!text-[14px] !bg-white"
                                                        selectedValue={value}
                                                        onChange={handleSelectChange2}
                                                    />
                                                </div>
                                            </>
                                        )}

                                        <div className="col-span-1 self-center flex justify-center">
                                            <Button
                                                type="submit"
                                                disabled={disable}
                                                className={`${disable ? "!bg-[#817878] !p-2" : " !p-2"
                                                    }`}
                                            >
                                                <img
                                                    src={Search}
                                                    className="cursor-pointer "
                                                    alt="Search"
                                                />
                                            </Button>
                                            <InActiveButton
                                                type="submit"
                                                disabled={disable}
                                                onClick={() => {
                                                    handleFilterIconClick();
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        maskImage: `url(${clearFilter})`,
                                                        WebkitMaskImage: `url(${clearFilter})`,
                                                        maskRepeat: "no-repeat",
                                                        WebkitMaskRepeat: "no-repeat",
                                                        maskPosition: "center",
                                                        WebkitMaskPosition: "center",
                                                        maskSize: "contain",
                                                        WebkitMaskSize: "contain",
                                                    }}
                                                    className="self-center pr-1 py-1 h-4 w-4 cursor-pointer mx-auto"
                                                />
                                            </InActiveButton>
                                        </div>
                                        <div
                                            className={`${props.orderId == null ? "" : "text-center"
                                                } col-span-2 self-center`}
                                        >
                                            <Button
                                                className={`${disable ? "!bg-[#817878]" : ""
                                                    } !text-[13px]`}
                                                disabled={disable}
                                                onClick={() => openDisapproved()}
                                            >
                                                Advance Search
                                            </Button>
                                        </div>
                                    </Grid>
                                </div>
                            </form> */}
                        </div>
                    </Grid>
                    <div className="mb-5 relative">
                        {loading ? (
                            <div className=" h-[400px] w-full flex py-5">
                                <div className="self-center mx-auto">
                                    <RotateLoader color="#333" />
                                </div>
                            </div>
                        ) : (
                            <DataTable
                                className="data-table-container" draggableColumns={false}
                                columns={columns}
                                data={deleteReport}
                                highlightOnHover
                                sortIcon={
                                    <>
                                        <div
                                            style={{
                                                maskImage: `url(${shorting})`,
                                                WebkitMaskImage: `url(${shorting})`,
                                                maskRepeat: "no-repeat",
                                                WebkitMaskRepeat: "no-repeat",
                                                maskPosition: "center",
                                                WebkitMaskPosition: "center",
                                                maskSize: "contain",
                                                WebkitMaskSize: "contain",
                                            }}
                                            className="ml-2 tabless"
                                        />
                                        {/* <img src={shorting} className="ml-2" alt="shorting" /> */}
                                    </>
                                }
                                pagination
                                paginationPerPage={10}
                                paginationComponentOptions={paginationOptions}
                                paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handleRowsPerPageChange}
                                noDataComponent={<CustomNoDataComponent />}
                            />
                        )}
                    </div>

                </Card>
            </div>

            <Modal isOpen={isModalOpen1} onClose={closeModal1}>
                <div className="text-center py-3">
                    <img src={Primary} alt="email Image" className="mx-auto my-4" />
                    <p className="text-3xl mb-0 mt-2 font-[800]">
                        {primaryMessage}
                    </p>
                    <p className="text-base font-medium mt-2">
                        {secondaryMessage}
                    </p>
                    <p className="text-base font-medium mt-2">
                        Redirecting you on Report List Page {timer} seconds.
                    </p>
                </div>
            </Modal>


            <Modal isOpen={isArchiveOpen} onClose={closeArchive}>
                <Button
                    onClick={closeArchive}
                    className="absolute right-[-13px] top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
                >
                    <img
                        src={Cross}
                        className="w-full h-full text-black rounded-full p-0"
                    />
                </Button>
                <div dangerouslySetInnerHTML={createMarkup(populatedHTML)} />
            </Modal>
        </>
    );
}


export default MailLogs
